
import '../sass/components/product-list.scss';
import '../sass/components/product-list-filter.scss';
import '../sass/components/pagingation.scss'
import {React, useContext, useEffect, useState} from "react";
import ProdItem from "./ProdItem";
import {useHistory, useParams} from "react-router-dom";
import Loading from "./Loading";
import {fetchDeliveryAddresses, fetchProducts} from "../apiRequests/api";
import Selects from "./Selects";
import ReactPaginate from 'react-paginate';
import {useAuth0} from "@auth0/auth0-react";
import {useBasePath} from "../hooks/useBasePath";

import {SigninContext} from "../contexts/SigninContext";


function ProductList (props) {
    const basePath = useBasePath();
    const [bulkUpdateClass, setBulkUpdateClass] = useState('')
    const {userName} = useContext(SigninContext)
    let {pageParam} = useParams()
    let {pageSizeParam} = useParams()
    const [pageSize, setPageSize] = useState(pageSizeParam)
    const [sort, setSort] = useState((new URLSearchParams(window.location.search)).get('sort') ?? '');

    const [pageSizeOptions] = useState(
        {
            current: {value: pageSize ? pageSize : 20, label: pageSize ? pageSize : 20},
            options: [
                { value: '10', label: '10' },
                { value: '20', label: '20' },
                { value: '50', label: '50' },
                { value: 'all', label: 'all' },
            ]
        }
    )
    const [sortOptions] = useState({
        current: {value: '', label: 'Default'},
        options: [
            {value: '', label: 'Default'},
            {value: 'description_desc', label: 'A-Z'},
            {value: 'description_asc', label: 'Z-A'},
            {value: 'price_asc', label: 'Price Low to High'},
            {value: 'price_desc', label: 'Price High to Low'},
        ]
    });
    const [allProducts, setAllProducts] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [totalQtyProducts, setTotalQtyProducts] = useState()

    // building parameters to call api
    let fetchParameters = {
        "pagedProducts": {
            PageSize: pageSize,
            StartIndex: 1+(pageParam*pageSizeParam)-pageSizeParam
         },
        "sort": sort,
        "basePath": basePath,
        "CustomerAccount" : userName
    }
    if (props.fetchParams){
        fetchParameters = Object.assign(fetchParameters,props.fetchParams)
    }
    let history = useHistory()
    const handlePageClick = (paginationData) => {
        window.scrollTo({top: 0, behavior: "smooth"});
        pageParam = +paginationData.selected+1
        let url = `${basePath}/${pageParam}/${pageSizeParam?pageSizeParam:20}`;
        if (sort !== '') {
            url += '?sort=' + sort;
        }
        history.push(url);
    }

    const {getIdTokenClaims} = useAuth0()

    async function LoadAllProducts () {
        setIsLoading(true)
          if (pageSize != pageSizeParam) {
            pageParam = 1
        }
        pageSizeParam = pageSize
        let url = `${basePath}/${pageParam}/${pageSizeParam}`;
        if (sort !== '') {
            url += '?sort=' + sort;
            fetchParameters.sort = sort;
        }
        history.push(url);
        const token = await getIdTokenClaims()
        if (pageSizeParam === 'all') {
            fetchParameters.pagedProducts = {}
        }
        if (token){
            const data = await fetchProducts(fetchParameters, token.__raw)
            if(data.error){
                setAllProducts(data)
            } else {
                setAllProducts(data.mainResult)
                setTotalQtyProducts(data.TotalRowCount)
            }

        } else {
            alert('Failed to connect Auth0!')
            window.location.href = '/'
        }
        setIsLoading(false)
    }
    function bulkAddHandler () {
        // TODO needs another solve (now is not enough time)
        var addButtons = document.querySelectorAll('.update-cart-product-list-table')
        addButtons.forEach(el=> el.click())
        setBulkUpdateClass('refreshed')
        setTimeout(function(){ setBulkUpdateClass('') }, 500);
    }


    useEffect(  () => {
        LoadAllProducts()
    },[userName,pageSize,pageParam,sort,props.fetchParams])

    return (
        <div className='product-list-component'>

            <Loading isLoading={isLoading}/>
            {!isLoading && userName &&
              <div
                className={`product-list-filter ${!allProducts.length && 'hide'}`}>
                  <div className="product-list-filter_paginate">
                      {pageSize !== 'all' && totalQtyProducts > 10 &&
                        <ReactPaginate
                          forcePage={pageParam - 1}
                          pageCount={totalQtyProducts / pageSize}
                          previousLabel={'previous'}
                          previousClassName={'previous'}
                          nextLabel={'next'}
                          nextClassName={'next'}
                          onPageChange={handlePageClick}
                          containerClassName={'pagination'}
                          activeClassName={'active'}
                        />}
                  </div>
                  {totalQtyProducts > 10 &&
                    <div className="product-list-filter_select">
                        <label>Per page :</label>
                        <Selects options={pageSizeOptions.options}
                                 setPropsValue={setPageSize} value={pageSize}/>
                    </div>
                  }
                  <div className="product-list-filter_select product-list-filter_sort">
                      <label>Sort:</label>
                      <Selects options={sortOptions.options}
                               setPropsValue={setSort} value={sort}/>
                  </div>
                  <div className="bulk-add">
                      <button onClick={() => bulkAddHandler()}
                              className={`bulk-add-btn ${bulkUpdateClass}`}>Bulk
                          Add<i className="fas fa-icon fa-cart-plus"></i>
                      </button>
                  </div>
              </div>
            }

            <ul className="product-list">

                {!(allProducts.error) ? (
                  allProducts && allProducts.map(product => {
                      return <li className="product-list-item" key={product.Code}><ProdItem product={product}/>
                            </li>;
                        })
                    ) : (
                        <div className="error-message">{allProducts.error}</div>
                    )
                    }


            </ul>

            <div
              className={`product-list-filter product-list-filter_bottom ${!allProducts.length && 'hide'}`}>
                <div className='product-list-filter_paginate'>
                    {pageSize !== 'all' && totalQtyProducts > 10 &&
                      <ReactPaginate
                        forcePage={pageParam - 1}
                        pageCount={totalQtyProducts / pageSize}
                        previousLabel={'previous'}
                        previousClassName={'previous'}
                        nextLabel={'next'}
                        nextClassName={'next'}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                      />}
                </div>
                <div className='product-list-filter_select'>
                    <label>Per page:</label>
                    <Selects options={pageSizeOptions.options}
                             setPropsValue={setPageSize} value={pageSize}/>
                </div>
                <div className="product-list-filter_select product-list-filter_sort">
                    <label>Sort:</label>
                    <Selects options={sortOptions.options}
                             setPropsValue={setSort} value={sort}/>
                </div>
                <div className='bulk-add'>
                    <button onClick={() => bulkAddHandler()}
                            className={`${bulkUpdateClass}`}>Bulk Add<i
                      className="fas fa-icon fa-cart-plus"></i></button>
                </div>
            </div>

        </div>
    )
}

export default ProductList