import React, {Fragment} from "react";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {fetchOneProduct} from "../apiRequests/api";
import Loading from "./Loading";
import wineWhite from "../assets/Wine-white.png";
import wineWhiteGrey from "../assets/Wine-white-grey.png";
import wineRed from "../assets/Wine-red.png";
import "../sass/components/product-item.scss";
import "../sass/components/product-detail.scss";
import "../sass/components/update-cart-btn.scss";
import {useAuth0} from "@auth0/auth0-react";
import {updateCart} from "../utils/cartTools";
import {useDispatch, useSelector} from "react-redux";
import {productFields, titleField} from "../settings";
import Image from "./Image";
import { resolveAvailabilityMsg } from '../utils/extraTools';

export default function ProdItem(props) {

    const dispatch = useDispatch()
    const cart = useSelector((state) => state.cart)
    const [cases, setCases] = useState(props.cases ? props.cases : '')
    const [bottles, setBottles] = useState(props.bottles ? props.bottles : '')
    const [product, setProduct] = useState({})
    if (process.env.REACT_APP_STOCK_CONTROL === '1') {
        var StockTBottles = +product.StockCases * +product.CaseSize + +product.StockBottles
        var StockTCases = +product.StockCases
    } else {
        var StockTBottles = 9999999
        var StockTCases = 9999999
    }
    const [isLoading, setLoading] = useState(true)
    const [cartUpdateStyles, setCartUpdateStyles] = useState('')
    const [cartAdd, setCartAdd] = useState('')
    const {id} = useParams()
    var prodCode = {
        ProductCode: id ? id : props.id
    }
    const {getIdTokenClaims} = useAuth0();
    const [customLabel, setCustomLabel] = useState(props.customLabel ? props.customLabel : ''); // Initialize customLabel state


    // change data regarding to product depending on page
    async function loadProduct() {
        if (id || props.id) {
            const token = await getIdTokenClaims()
            const singleProduct = (await fetchOneProduct(prodCode, token.__raw)).mainResult
            setProduct(singleProduct)
            setLoading(false)
            if (!props.id) cart.items.forEach(el => {
                if (el.Code == singleProduct.Code) {
                    setCases(el.CasesQty)
                    setBottles(el.BottlesQty)
                }
            })
        } else {
            setProduct(props.product)
            cart.items.forEach(el => {
                if (el.Code == props.product.Code) {
                    setCases(el.CasesQty)
                    setBottles(el.BottlesQty)
                }
            })
        }
    }

    // Build arrays for Notes and Icon groups

    // Notes
    const notesGroup = [];
    const regex = /(<([^>]+)>)/ig;
    if (process.env.REACT_APP_NOTES_DISPLAY.includes('1') && product.Notes1 && product.Notes1 !== 'A') {
        notesGroup.push(product.Notes1.replace(regex, ''));
    }
    if (process.env.REACT_APP_NOTES_DISPLAY.includes('2') && product.Notes2 && product.Notes2 !== 'A') {
        notesGroup.push(product.Notes2.replace(regex, ''));
    }
    if (process.env.REACT_APP_NOTES_DISPLAY.includes('3') && product.Notes3 && product.Notes3 !== 'A') {
        notesGroup.push(product.Notes3.replace(regex, ''));
    }
    if (process.env.REACT_APP_NOTES_DISPLAY.includes('4') && product.Notes4 && product.Notes4 !== 'A') {
        notesGroup.push(product.Notes4.replace(regex, ''));
    }

    // Wine Making Notes
    var wineMakingNotes = "";
    if (process.env.REACT_APP_NOTES_DISPLAY.includes('W') && product.WineMakingNotes && product.WineMakingNotes !== 'A') {
        wineMakingNotes = product.WineMakingNotes.replace(regex, '');
    }
    // Type and Style
    const locationGroup = [];
    if (product.CountryDescription) {
        locationGroup.push(toTitles(product.CountryDescription));
    }
    if (product.Appellation && product.Appellation !== '0' && product.Appellation !== 'Not Set') {
        locationGroup.push(product.Appellation);
    }

    // Type and Style
    const typeGroup = [];
    if (product.Type && product.Type !== 'Not Set') {
        typeGroup.push(product.Type);
    }
    if (product.Style && product.Style !== 'Not Set') {
        typeGroup.push(product.Style);
    }
    if (product.ClosureStyle && product.ClosureStyle !== 'Not Set') {
        typeGroup.push(product.ClosureStyle);
    }

    // Organic / Biodynamic
    const organicGroup = [];
    if (product.OrganicBiodynamic == 'Both' || product.OrganicBiodynamic == 'Organic') {
        organicGroup.push('Organic');
    }
    const bioGroup = [];
    if (product.OrganicBiodynamic == 'Both' || product.OrganicBiodynamic == 'Biodynamic') {
        bioGroup.push('Biodynamic');
    }

    // Suitable for
    // TODO - In React can we loop through product.Allergen_SuitableFor* and condense this function?
    const suitableGroup = [];
    if (product.Allergen_SuitableForVegetarian == '1') {
        suitableGroup.push('Vegetarian');
    }
    if (product.Allergen_SuitableForVegan == '1') {
        suitableGroup.push('Vegan');
    }
    if (product.Allergen_SuitableForGlutenFreeDiet == '1') {
        suitableGroup.push('Gluten Free');
    }
    if (product.Allergen_SuitableForEggIntoleranceDiet == '1') {
        suitableGroup.push('Egg Intolerance');
    }
    if (product.Allergen_SuitableForLactoseIntoleranceDiet == '1') {
        suitableGroup.push('Lactose Intolerance');
    }
    if (product.Allergen_SuitableForLowFatDiet == '1') {
        suitableGroup.push('Low Fat Diet');
    }

    // Allergens
    // TODO - In React can we loop through product.Allergen_* and condense this function?
    // TODO - or do we manage this in the PHP backend controllers and send this array to React?
    const allergenGroup = [];
    if (product.Allergen_Sulphites == 1) {
        allergenGroup.push('Sulphites');
    }
    if (product.Allergen_Milk == 1) {
        allergenGroup.push('Milk');
    }
    if (product.Allergen_Egg == 1) {
        allergenGroup.push('Egg');
    }
    if (product.Allergen_Fish == 1) {
        allergenGroup.push('Nuts');
    }
    if (product.Allergen_Cereals == 1) {
        allergenGroup.push('Cereals');
    }
    if (product.Allergen_Nuts == 1) {
        allergenGroup.push('Nuts');
    }
    if (product.Allergen_Crustaceans == 1) {
        allergenGroup.push('Crustaceans');
    }
    if (product.Allergen_Molluscs == 1) {
        allergenGroup.push('Molluscs');
    }
    if (product.Allergen_Peanuts == 1) {
        allergenGroup.push('Peanuts');
    }
    if (product.Allergen_Soybeans == 1) {
        allergenGroup.push('Soybeans');
    }
    if (product.Allergen_Mustard == 1) {
        allergenGroup.push('Mustard');
    }
    if (product.Allergen_Sesame == 1) {
        allergenGroup.push('Sesame');
    }
    if (product.Allergen_Lupin == 1) {
        allergenGroup.push('Lupin');
    }

    // Food Suggestion
    // TODO - In React can we loop through product.Allergen_* and condense this function?
    // TODO - or do we manage this in the PHP backend controllers and send this array to React?
    const suggestionGroup = [];
    if (product.FoodSuggestion1 !== 'Not Set') {
        suggestionGroup.push(product.FoodSuggestion1);
    }
    if (product.FoodSuggestion2 !== 'Not Set') {
        suggestionGroup.push(product.FoodSuggestion2);
    }
    if (product.FoodSuggestion3 !== 'Not Set') {
        suggestionGroup.push(product.FoodSuggestion3);
    }
    if (product.FoodSuggestion4 !== 'Not Set') {
        suggestionGroup.push(product.FoodSuggestion4);
    }
    if (product.FoodSuggestion5 !== 'Not Set') {
        suggestionGroup.push(product.FoodSuggestion5);
    }

    function addToCartHandler() {
        let currentProduct
        if (id || props.id) {
            currentProduct = product
        } else {
            currentProduct = props.product
        }

        if (cases > 0 || bottles > 0) {

            let newCases
            let newBottles
            let newTotal = +cases * currentProduct.CaseSize + +bottles

            newCases = Math.floor(newTotal / currentProduct.CaseSize)
            newBottles = newTotal % currentProduct.CaseSize

            if (newTotal > StockTBottles) {
                setCases(StockTCases)
                newCases = StockTCases
                setBottles(StockTBottles % currentProduct.CaseSize)
                newBottles = (StockTBottles % currentProduct.CaseSize)
            } else {
                setCases(newCases)
                setBottles(newBottles)
            }

            const newCart = updateCart(currentProduct, cart, 'ADD', newCases, newBottles)
            dispatch({type: "UPDATE_CART", payload: newCart})
            setCartAdd('added')
            setTimeout(() => setCartAdd(''), 3000);
            setCartUpdateStyles('refreshed')
            setTimeout(() => setCartUpdateStyles(''), 500);
        } else {
            const newCart = updateCart(currentProduct, cart, "REMOVE")
            dispatch({type: "UPDATE_CART", payload: newCart})
        }
    }

    useEffect(() => {
        loadProduct()
    }, [])

    if (isLoading && id) {
        return <Loading/>;
    }
    var imageFile = /[^/]*$/.exec(product.Image1_URL)[0];
    // Get correct image for product.
    var image = process.env.REACT_APP_VINTNER_IMAGES && product.Image1_URL !== "" && !process.env.REACT_APP_URL_TO_IMAGES ? product.Image1_URL : product.Image1_URL !== "" && process.env.REACT_APP_URL_TO_IMAGES ? process.env.REACT_APP_URL_TO_IMAGES + imageFile
        : (product.Type == "White") ? wineWhite
            : (product.Type == "Red") ? wineRed : wineWhiteGrey;


    if (process.env.REACT_APP_UNIT_FLAG !== "1") {
        var Unit = product.CaseSize;
    } else {
        var Unit = "1";
    }

    function toTitles(s) {
        return s.replace(/\w\S*/g, function (t) {
            return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
        });
    }

    return (
        <Fragment>

            <div className="product-details">

              <div className="product-list-item_wrapper">
                  <div className="product-list-item_left">
                      {process.env.REACT_APP_IMAGES_JSON ?
                        <Image Alt={product[titleField]}
                               ProductCode={product.Code}
                               Placeholder={image}/> :
                        <Link to={`/product-detail/${product.Code}`}>
                            <img
                              src={image}
                              alt={product[titleField]}
                              className="nav-user-profile rounded-circle"
                            />
                        </Link>}
                      <span className="print_icon"
                            onClick={() => window.print()}><i
                        className="fa-3x fa-sharp fa-regular fa-print" title="Print wine page"></i></span>
                  </div>
                  <div className="product-list-item_right">
                      {product[titleField] != '' &&
                        <div className="product-list-item-title-link-wrapper">
                            <Link className="product-list-item-title-link"
                                  to={`/product-detail/${product.Code}`}>
                                <h2
                                  className="product-list-item-title">{product[titleField]}</h2>
                            </Link>
                        </div>
                      }

                      <div className="product-list-item_right__top">
                          {productFields.map((el, index) => el.name && !el.combined ? (

                              <div key={index}
                                   className="product-list-item_inner">
                                  {el.name === "Code" &&
                                    <>
                                        <i
                                          className={`fas fa-icon ${el.iconClass}`}></i>
                                        {product['BinNo'] !== "" && process.env.REACT_APP_BIN === '1' ? (
                                            <p>{product['BinNo']}</p>
                                          ) :
                                          <p>{decodeURIComponent(product['Code'])}</p>}
                                    </>
                                  }
                                  {el.name !== "Code" &&
                                    product[el.name] && product[el.name] !== "    " && product[el.name] !== " " &&
                                    <><i
                                      className={`fas fa-icon ${el.iconClass}`}></i>
                                        <p>{product[el.name]}</p>
                                    </>
                                  }
                              </div>
                            ) : (
                              <div key={index}
                                   className="product-list-item_inner">
                                  <i
                                    className={`fas fa-icon ${el.iconClass}`}></i>
                                  <p>
                                      {el.combined.map((combinedEl, index) => (

                                        <span
                                          key={index}>{product[combinedEl.name]}{index < el.combined.length - 1 && el.separator}{index === el.combined.length - 1 && el.append}</span>
                                      ))}
                                  </p>
                              </div>
                            )
                          )}
                          <div className="custom-label-section">
                              <p>{customLabel}</p>
                          </div>
                      </div>
                      <div className="product-list-item_right__bottom">
                          <div className="product-list-item_inner_wrapper">
                              {resolveAvailabilityMsg(product).length > 1 ?
                                <p
                                  className="product-list-item-input-label text-center">
                                    {resolveAvailabilityMsg(product)}
                                </p>
                                : ''}
                              {process.env.REACT_APP_SHOW_STOCK === '3' && resolveAvailabilityMsg(product, 'on order').length > 1 ?
                                <p
                                  className="product-list-item-input-label text-center">
                                    {resolveAvailabilityMsg(product, 'on order')}
                                </p>
                                : ''}
                              <div
                                className="product-list-item_right__bottom__left">
                                  {process.env.REACT_APP_UNIT_FLAG !== "1" || product.CaseSize === 1 ? (
                                    <div
                                      className="product-list-item_inner input">
                                        <h6
                                          className='product-list-item-label cases'>{product.CustomLabel ?? 'Cases'}</h6>
                                        <div
                                          className='product-list-item__qty-row'>
                                            <input type="button" value="-"
                                                   className="button-minus no_print"
                                                   onClick={() => setCases(cases > 0 && cases - 1)}
                                                   data-field="quantity"
                                            />
                                            <input type="number"
                                                   id="stock-cases-input"
                                                   name="stock-cases-input"
                                                   autoComplete="off" max="1"
                                                   value={cases}
                                                   placeholder={'0'}
                                                   onChange={(e) => setCases(+e.target.value < 0 ? '' : +e.target.value)}
                                                   min="0" max={StockTCases}
                                            />
                                            <input type="button" value="+"
                                                   className="button-plus no_print"
                                                   onClick={() => setCases(+cases + 1)}
                                                   data-field="quantity"
                                            />
                                        </div>
                                    </div>
                                  ) : ('')
                                  }
                                  {((process.env.REACT_APP_ONLY_SELL_MULTIPLES_OF && product.OnlySellMultiplesOf <= 1 && process.env.REACT_APP_UNIT_FLAG !== "2") || (!process.env.REACT_APP_ONLY_SELL_MULTIPLES_OF && process.env.REACT_APP_UNIT_FLAG !== "2")) && product.CaseSize > 1 ? (
                                    <div
                                      className="product-list-item_inner input">
                                        <h6
                                          className='product-list-item-label bottles'>Bottles</h6>
                                        <div
                                          className='product-list-item__qty-row'>
                                            <input type="button" value="-"
                                                   className="button-minus no_print"
                                                   onClick={() => setBottles(bottles > 0 && bottles - 1)}
                                                   data-field="quantity"
                                            />
                                            <input type="number"
                                                   id="stock-bottles-input"
                                                   name="stock-bottles-input"
                                                   autoComplete="off"
                                                   value={bottles}
                                                   placeholder={'0'}
                                                   onChange={(e) => setBottles(+e.target.value < 0 ? '' : +e.target.value)}
                                                   min="0" max={StockTBottles}
                                            />
                                            {bottles < StockTBottles &&
                                              <input type="button" value="+"
                                                     className="button-plus no_print"
                                                     onClick={() => setBottles(+bottles + 1)}
                                                     data-field="quantity"
                                              />}
                                            {bottles >= StockTBottles &&
                                              <input type="button" value="+"
                                                     className="button-plus no_print"
                                                     data-field="quantity"
                                              />}
                                        </div>
                                    </div>) : (<></>)
                                  }
                              </div>
                          </div>
                          <div
                            className="product-list-item_right__bottom__right">
                              {process.env.REACT_APP_UNIT_FLAG !== "1" &&
                                <div
                                  className="product-list-item_inner case-nett">
                                    <h6
                                      className='product-list-item-label'>Case
                                        Nett:</h6>
                                    <p>{window.currency}{(Math.round((product.CasePrice * 1000) / 10) / 100).toFixed(2)}</p>
                                </div>
                              }
                              {process.env.REACT_APP_ONLY_SELL_MULTIPLES_OF ? product.OnlySellMultiplesOf <= 1 : !process.env.REACT_APP_ONLY_SELL_MULTIPLES_OF && process.env.REACT_APP_UNIT_FLAG !== "2" ? (
                                <div
                                  className="product-list-item_inner bottle-nett">
                                    <h6
                                      className='product-list-item-label'>Bottle
                                        Nett:</h6>
                                    <p>{window.currency}{(Math.round((product.BottleNett * 1000) / 10) / 100).toFixed(2)}</p>
                                </div>) : (<></>)
                              }
                              <div
                                className="product-list-item_inner update-cart">
                                  {StockTBottles > 0 &&
                                    <button
                                      className={`update-cart-btn update-cart-product-list-table ${cartUpdateStyles}`}
                                      onClick={() => addToCartHandler()}>
                                        <p>Add to Cart</p>&nbsp;
                                        <i
                                          className="fas fa-icon fa-shopping-cart"></i>
                                    </button>}
                                  {StockTBottles < 1 &&
                                    <button
                                      className={`update-cart-btn disabled update-cart-product-list-table`}
                                      disabled>
                                        <p>Out of Stock</p>
                                        <i
                                          className="fas fa-icon fa-shopping-cart"></i>
                                    </button>}
                                  <div
                                    className={`add-to-cart ${cartAdd}`}>{product[titleField]} added
                                      to
                                      basket
                                  </div>

                                  {/*<Popup className='prvoduct_popup' onOpen={()=>addToCartHandler()} trigger={(*/}
                                  {/*    <button className={`update-cart-btn update-cart-product-list-table ${cartUpdateStyles}`}>*/}
                                  {/*        <p>Add to Cart</p>*/}
                                  {/*        <i className="fas fa-icon fa-shopping-cart"></i>*/}
                                  {/*    </button>*/}
                                  {/*)} position="bottom center">*/}
                                  {/*    */}
                                  {/*</Popup>*/}
                              </div>
                          </div>

                      </div>
                  </div>
              </div>

              <div className="product-description">
                  {process.env.REACT_APP_NOTES_DISPLAY.includes('1') && product.Notes1 && product.Notes1 !== 'A' &&
                    <>{process.env.REACT_APP_NOTES1_TITLE &&
                      <h3>{process.env.REACT_APP_NOTES1_TITLE}</h3>
                    }
                        <p>{product.Notes1}</p>
                    </>
                  }

                  {process.env.REACT_APP_NOTES_DISPLAY.includes('2') && product.Notes2 && product.Notes2 !== 'A' &&
                    <>{process.env.REACT_APP_NOTES2_TITLE &&
                      <h3>{process.env.REACT_APP_NOTES2_TITLE}</h3>
                    }
                        <p>{product.Notes2}</p>
                    </>
                  }

                  {process.env.REACT_APP_NOTES_DISPLAY.includes('3') && product.Notes3 && product.Notes3 !== 'A' &&
                    <>{process.env.REACT_APP_NOTES3_TITLE &&
                      <h3>{process.env.REACT_APP_NOTES3_TITLE}</h3>
                    }
                        <p>{product.Notes3}</p>
                    </>
                  }

                  {process.env.REACT_APP_NOTES_DISPLAY.includes('4') && product.Notes4 && product.Notes4 !== 'A' &&
                    <>{process.env.REACT_APP_NOTES4_TITLE &&
                      <h3>{process.env.REACT_APP_NOTES4_TITLE}</h3>
                    }
                        <p>{product.Notes4}</p>
                    </>
                  }

                  {process.env.REACT_APP_NOTES_DISPLAY.includes('W') && product.WineTastingNotes && product.WineTastingNotes !== 'A' &&
                    <>{process.env.REACT_APP_NOTESW_TITLE &&
                      <h3>{process.env.REACT_APP_NOTESW_TITLE}</h3>
                    }
                        <p>{product.WineTastingNotes}</p>
                    </>
                  }
                  {process.env.REACT_APP_NOTES_DISPLAY.includes('S') && product.ServingSuggestion && product.ServingSuggestion !== 'A' &&
                    <>{process.env.REACT_APP_NOTESS_TITLE &&
                      <h3>{process.env.REACT_APP_NOTESS_TITLE}</h3>
                    }
                        <p>{product.ServingSuggestion}</p>
                    </>
                  }
              </div>

              <div className="product-info-circles">
                  {process.env.REACT_APP_VINTAGE_DISPLAY === "1" && product.Vintage !== "    " && product.Vintage !== " " ? (
                    <div className="info-circle">

                        <div className={"circle"}>
                            <div className="fa-thin fa-calendar fa-2xl"></div>
                        </div>
                        <p>{product.Vintage}</p>
                    </div>) : ""}

                  {process.env.REACT_APP_LOCATION_DISPLAY === "1" && product.CountryDescription.length ? (
                    <div className="info-circle">
                        <div className={"circle"}>
                            <div
                              className="fa-thin fa-earth-europe fa-2xl"></div>
                        </div>
                        <ul>
                            {locationGroup.map((item) => (
                              <li>{item}</li>
                            ))}
                        </ul>
                    </div>) : ""}

                  {process.env.REACT_APP_TYPE_DISPLAY === "1" && typeGroup.length ? (
                    <div className="info-circle">
                        <div className={"circle"}>
                            <div
                              className="fa-thin fa-wine-glass fa-2xl"></div>
                        </div>
                        <ul>
                            {typeGroup.map((item) => (
                              <li>{item}</li>
                            ))}
                        </ul>
                    </div>) : ""}

                  {process.env.REACT_APP_WINEMAKER_DISPLAY === "1" && product.Winemaker.Name ? (
                    <div className="info-circle">
                        <div className={"circle"}>
                            <div className="fa-thin fa-grapes fa-2xl"></div>
                        </div>
                        <p>{product.Winemaker.Name}</p>
                    </div>) : ""}

                  {process.env.REACT_APP_ABV_DISPLAY === "1" && product.AlcoholicPercentage ? (
                    <div className="info-circle">
                        <div className={"circle"}>
                            <div className="fa-thin fa-percent"></div>
                        </div>
                        <p>{product.AlcoholicPercentage}%</p>
                    </div>) : ""}

                  {process.env.REACT_APP_ORGANIC_DISPLAY === "1" && organicGroup.length ? (
                    <div className="info-circle">
                        <div className={"circle"}>
                            <div className="fa-thin fa-leaf fa-2xl"></div>
                        </div>
                        <ul>
                            {organicGroup.map((item) => (
                              <li>{item}</li>
                            ))}
                        </ul>
                    </div>) : ""}

                  {process.env.REACT_APP_BIO_DISPLAY === "1" && bioGroup.length ? (
                    <div className="info-circle">
                        <div className={"circle"}>
                            <div
                              className="fa-thin fa-wine-glass fa-2xl"></div>
                        </div>
                        <ul>
                            {bioGroup.map((item) => (
                              <li>{item}</li>
                            ))}
                        </ul>
                    </div>) : ""}

                  {process.env.REACT_APP_ALLERGEN_DISPLAY === "1" && allergenGroup.length ? (
                    <div className="info-circle">
                        <div className={"circle"}>
                            <div
                              className="fa-thin fa-circle-exclamation fa-2xl"></div>
                        </div>
                        <ul>
                            {allergenGroup.map((item) => (
                              <li>{item}</li>
                            ))}
                        </ul>
                    </div>) : ""}

                  {process.env.REACT_APP_SUITABLE_DISPLAY === "1" && suitableGroup.length ? (
                    <div className="info-circle">
                        <div className={"circle"}>
                            <div className="fa-thin fa-wheat fa-2xl"></div>
                        </div>
                        <ul>
                            {suitableGroup.map((item) => (
                              <li>{item}</li>
                            ))}
                        </ul>
                    </div>) : ""}

                  {process.env.REACT_APP_SUGGESTION_DISPLAY === "1" && suggestionGroup.length ? (
                    <div className="info-circle">
                        <div className={"circle"}>
                            <div
                              className="fa-thin fa-fork-knife fa-2xl"></div>
                        </div>
                        <ul>
                            {suggestionGroup.map((item) => (
                              <li>{item}</li>
                            ))}
                        </ul>
                    </div>) : ""}

                  {process.env.REACT_APP_EXCISE_DISPLAY === "1" && product.ExciseDescription ? (
                    <div className="info-circle">
                        <div className={"circle"}>
                                    <div className="fa-thin fa-file-invoice"></div>
                        </div>
                        <p>{product.ExciseDescription}</p>
                    </div>) : ""}

              </div>
          </div>

      </Fragment>
    )
}


